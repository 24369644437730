import React from "react"
// import { Link } from "gatsby"
import "../../assets/css/blog.css"
// import Logo from "../../images/logo.png"

// import appStoreIcon from "../../images/app-store-icon.png"
// import googlePlayIcon from "../../images/google-pay-icon.png"
// import fbIcon from "../../images/fb-icon.svg"
// import twitterIcon from "../../images/twitter.svg"
// import instacon from "../../images/insta-icon.svg"

// import video from "../../images/video-icon.svg"

import lineImage from "../../images/line2.png"
// import mailIcon from "../../images/mail-icon.svg"
// import callIcon from "../../images/call-icon.svg"
// import livechat from "../../images/live-chat-icon.svg"

import Layout from "../../components/layout"

import handIcon from "../../images/hand.svg"
import chatbottom from "../../images/chat-bottom-icon.svg"
import chatProfile from "../../images/chat-profile.png"
import closeChat from "../../images/close-icon.svg"
import SEO from "../../components/seo"

// Blog Page Image 
import ArticleMainImage from '../../images/blog/detail/article-main.png';
import ArticleDetailImage1 from '../../images/blog/detail/article-main-1.png';
import ArticleDetailImage2 from '../../images/blog/detail/article-main-2.png';

import ArticleMainImage1 from '../../images/blog/article-main-1.png'
import ArticleMainImage2 from '../../images/blog/article-main-2.png'
import ArticleMainImage3 from '../../images/blog/article-main-3.png'
import ArticleMainImage4 from '../../images/blog/article-main-4.png'

import UserProfileImage from '../../images/blog/userProfile.png';
import UnionIcon from '../../images/blog/umrella.svg';
import ShareIcon from '../../images/blog/share.svg';
import Footer from "../../components/footer";


const BlogDetailPage = (props) => (
    <Layout rest={props} layoutType="blogHeader">
        <SEO title="Blog Detail Page" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
        <div className="chat-content" style={{ display: 'none' }}>
            <div className="chat-content-circle">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                    <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                        <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                            <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                            <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                            <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                        </g>
                    </g>
                </svg>
            </div>
            <div className="chat-box">

                <div className="chatbox-inner">
                    <div className="chat-profile">
                        <img className="" src={chatProfile} alt="" />
                    </div>
                    <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
                    <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
                </div>
                <img className="chat-bottom" src={chatbottom} alt="" />
            </div>

        </div>

         {/* --------------Blog Page --------- */}
         <div className="blog-page-container blog-detail-container">
            <section className="blog-container">
                <div className="blog-single blog-main-single">
                    <div className="blog-content">
                        <h2 className="blog-title">Which Generation Is Buying the Most Electric Vehicles?</h2>
                    </div>
                    <div className="blog-image">
                        <img className="" src={ArticleMainImage} alt="" />
                    </div>
                    <div className="blog-content">
                        <div className="blog-caption">
                            <span className="userImage">
                                <img className="unionIcon" src={UnionIcon} alt="" />
                            </span>
                            <label><b className="userName">Dan Ariely</b> |&nbsp; May 5, 2019</label>
                            <span className="shareImage">
                                <img className="" src={ShareIcon} alt="" />
                            </span>
                        </div>
                        <div className="blog-content-container">
                            <h4>You May Be Surprised.</h4>
                            <p>The electric vehicle (EV) industry has come a long way since Larry David and your hip friend bought their first Prius in the late 90s. In 2018, the electric vehicle segment more than doubled versus 2017. As the popularity of electric vehicles grows, you might think Millennials would be leading the charge, oat milk latte and avocado toast in hand. But you might be surprised to learn that they’re not all lining up for the latest Tesla release.<br /> <br />According to TrueCar data, Gen X is buying the most EVs, at 39.8%, followed closely by Millennials at 34.9%. However, Boomers are actually the ones buying the greatest mix of all green vehicles, which includes Electric, Hybrid, Plug-in Hybrid, and Fuel Cell vehicles. That’s right, the generation that can remember a time before the Internet is embracing green vehicles more than the electronic generation itself.</p>
                            <h4>1. More Money, More EVs</h4>
                            <p>Electric vehicle sticker prices are often much higher than those of comparable gas vehicles. “There’s still a price premium on alternative powertrains, so EV purchases are strongly correlated with income levels, which gives Boomers a natural advantage over Millennials,” said Cari Crane, Director of Insights at TrueCar’s ALG.<br /> <br /> “But Millennials are quickly catching up. We’re seeing affluent Millennials gravitate toward EVs at higher rates than hybrids or even gas-powered vehicles,” says Crane. Millennials with an income of $70K or higher purchase electric vehicles at a rate four times that of those who make less than $70K.</p>
                            <img className="state-img" src={ArticleDetailImage1} alt="" />
                            <p>Affluent Millennials gravitate toward EVs at higher rates than hybrids or even gas-powered vehicles[/caption]<br /><br />However, for the rest of their generation, gas cars still reign supreme, a fact that may be attributed to not only the lower cost of gas vehicles but also the fluctuation of gas prices across the country. While in some areas, the price of gas may be spiking, in others it has remained steady, thus discouraging Millennials from making the switch.</p>
                            <h4>2. Plug & Play</h4>
                            <p>Arguably the biggest hurdle that Millennials have to overcome with EVs is the question of where do you actually charge these cars? Since a car can’t exactly fit in your living room, it can be challenging to find the right space to do the job. Today, 37% of Millennials live in urban areas, and while charging stations are becoming more widely available, many smaller apartment buildings don’t offer these amenities.<br /><br />Even for those living in the suburbs, public charging stations may be more difficult to access. Nearly one-third of all EV charging stations are located in California, where 5% of new vehicle registrations are all-electric. So, while Millennials located in cities who embrace EVs are in for good news, others may have to wait a bit longer to plug in.</p>
                            <img className="state-img" src={ArticleDetailImage2} alt="" />
                            <p className="mb">Additionally, the limited battery range can cause quite a bit of panic in potential owners. If you tend to take longer car trips, you may be concerned that the battery range simply won’t get you to your destination. It’s one thing to do a quick fill-up at the gas station, but it’s another to stop for a few hours while you charge your car. As more charging stations pop up and increased battery sizes become available, it is likely that some of this anxiety will subside for car buyers.</p>
                        </div>
                        <div className="tags-container">
                            <label>Tags</label>
                            <ul>
                                <li>#News & Media</li>
                                <li>#Trends</li>
                                <li>#Tips</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="related-blogs-container">
                    <h4>Continue Reading</h4>
                    <div className="blog-grid">
                    <div className="blog-single">
                        <div className="blog-image">
                            <img className="" src={ArticleMainImage1} alt="" />
                        </div>
                        <div className="blog-content">
                            <h3 className="blog-title">The Best Windshield Wipers For Your Car</h3>
                            <div className="blog-caption">
                                <span className="userImage">
                                    <img className="" src={UserProfileImage} alt="" />
                                </span>
                                <label><b className="userName">Dan Ariely</b> |&nbsp; May 5, 2019</label>
                                <span className="shareImage">
                                    <img className="" src={ShareIcon} alt="" />
                                </span>
                            </div>
                            <div className="blog-content-container">
                                <p>If you’re reading this, chances are your car’s windshield wipers are on their way out. But what should you replace them with? We found the best wipers worth their weight in rubber.</p>
                            </div>
                            <div className="tags-container">
                                <ul>
                                    <li>#News & Media</li>
                                    <li>#Trends</li>
                                    <li>#Tips</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="blog-single">
                        <div className="blog-image">
                            <img className="" src={ArticleMainImage2} alt="" />
                        </div>
                        <div className="blog-content">
                            <h3 className="blog-title">The Best Synthetic Oil and Why You Need It</h3>
                            <div className="blog-caption">
                                <span className="userImage">
                                    <img className="unionIcon" src={UnionIcon} alt="" />
                                </span>
                                <label><b className="userName">Dan Ariely</b> |&nbsp; May 5, 2019</label>
                                <span className="shareImage">
                                    <img className="" src={ShareIcon} alt="" />
                                </span>
                            </div>
                            <div className="blog-content-container">
                                <p>It’s one of the most common maintenance items for your vehicle, and it’s also one of the most important. When changed at proper intervals, the right motor oil can help keep your engine running as your vehicle’s manufacturer intended. Here’s what you should know about synthetic oil, and which oils we recommend.</p>
                            </div>
                            <div className="tags-container">
                                <ul>
                                    <li>#News & Media</li>
                                    <li>#Trends</li>
                                    <li>#Tips</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="blog-single">
                        <div className="blog-image">
                            <img className="" src={ArticleMainImage3} alt="" />
                        </div>
                        <div className="blog-content">
                            <h3 className="blog-title">These highly-rated car batteries are long lasting and quick to arrive</h3>
                            <div className="blog-caption">
                                <span className="userImage">
                                    <img className="unionIcon" src={UnionIcon} alt="" />
                                </span>
                                <label><b className="userName">Dan Ariely</b> |&nbsp; May 5, 2019</label>
                                <span className="shareImage">
                                    <img className="" src={ShareIcon} alt="" />
                                </span>
                            </div>
                            <div className="blog-content-container">
                                <p>When it’s time to buy a new battery for your vehicle, the good news is there’s no shortage of options. Gone are the days of maintaining your battery by adding distilled water when necessary (if you don’t remember those, be thankful). Modern batteries are built to last and are virtually maintenance-free.</p>
                            </div>
                            <div className="tags-container">
                                <ul>
                                    <li>#News & Media</li>
                                    <li>#Trends</li>
                                    <li>#Tips</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="blog-single">
                        <div className="blog-image">
                            <img className="" src={ArticleMainImage4} alt="" />
                        </div>
                        <div className="blog-content">
                            <h3 className="blog-title">Future Classics for Today’s Car Enthusiast</h3>
                            <div className="blog-caption">
                                <span className="userImage">
                                    <img className="unionIcon" src={UnionIcon} alt="" />
                                </span>
                                <label><b className="userName">Dan Ariely</b> |&nbsp; May 5, 2019</label>
                                <span className="shareImage">
                                    <img className="" src={ShareIcon} alt="" />
                                </span>
                            </div>
                            <div className="blog-content-container">
                                <p>Many people think collector cars need to be old, guarded by velvet ropes, and require you to refinance your home before you can even think about getting behind the wheel. While there is some truth to that, it only represents one slice of the collector car market. Used cars of today are collectible classics in the making, so here are some affordable picks to get your gears turning.</p>
                            </div>
                            <div className="tags-container">
                                <ul>
                                    <li>#News & Media</li>
                                    <li>#Trends</li>
                                    <li>#Tips</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
         </div>
       
        <div className="break-section">
            <img src={lineImage} alt="line" />
        </div>
        <Footer />
        {/* <section className="contact-container">
            <div className="container">
                <div className="logo-container">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="contact-inner">
                    <div className="first-col">
                        <ul>
                            <li> <img src={mailIcon} alt="mail" /> <p>help@servicecontract.com</p>
                                <span>24/7 -  Available when you need us</span>
                            </li>
                            <li> <img src={callIcon} alt="call" /><p>1-855-695-2255</p></li>
                            <li> <img src={livechat} alt="liev chat" /><p>Live Chat</p></li>
                        </ul>
                    </div>
                    <div className="second-col">
                        <ul>
                            <li><Link to="/vehicleInfo">Get Your Price</Link></li>
                            <li><Link to="">What's Covered</Link></li>
                            <li><Link to="">Extra Benefits</Link></li>
                            <li><Link to="">Claim</Link></li>
                            <li><Link to="">Our Partners</Link></li>
                            <li><Link to="">Who We Are</Link></li>
                            <li><Link to="">FAQs</Link></li>
                            <li><Link to="">API</Link></li>
                        </ul>
                    </div>
                    <div className="third-col">
                        <ul>
                            <li><Link to="">Who We Are</Link></li>
                            <li><Link to="">Blog</Link></li>
                            <li><Link to="">Extra Benefits</Link></li>
                            <li><Link to="">Contact</Link></li>
                            <li><Link to="">Join the Team</Link></li>
                            <li><Link to="">Legal Stuff</Link></li>
                            <li><Link to="">Privacy Policy</Link></li>
                            <li><Link to="">Terms of Service</Link></li>
                        </ul>
                    </div>
                    <div className="fourth-col">
                        <div className="store-icon">
                            <h6>Get Our App</h6>
                            <span><img src={appStoreIcon} alt="Apple store" /></span>
                            <span><img src={googlePlayIcon} alt="Googole play store" /></span>
                        </div>

                        <div className="follow-icon">
                            <h6>Follow Us</h6>
                            <span><img src={fbIcon} alt="facebook" /></span>
                            <span><img src={twitterIcon} alt="Twitter" /></span>
                            <span><img src={instacon} alt="instagra," /></span>
                            <span><img src={video} alt="youtube" /></span>
                        </div>

                    </div>
                </div>

            </div>


        </section>
        <section className="copyright-container">
            <div className="container">
                <div className="copyright">
                    <small>Copyright servicecontract.com © 2019</small>
                    
                </div>
            </div>

        </section> */}

    </Layout>
)

export default BlogDetailPage
